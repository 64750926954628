<template>
	<div>
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'routerView',
};
</script>

<style scoped></style>
